@font-face {
  font-family: terminus;
  src: url('/assets/fonts/TerminusTTF-4.47.0.ttf') format('truetype');
  /* LICENSE at /assets/fonts/COPYING */
}

@font-face {
  font-family: terminus;
  src: url('/assets/fonts/TerminusTTF-Bold-4.47.0.ttf') format('truetype');
  /* LICENSE at /assets/fonts/COPYING */
  font-weight: bold;
}

body {
  background-color: #202020;
}

div.f0 {
  max-width: 900px;
  margin: auto;
}

p, h1, h2, h3, h4, h5, h6, ul, td, blockquote {
  color: #F0F0F0;
  font-weight: 500;
  font-family: terminus;
}

p, ul, td {
  font-size: 20px;
}

a {
  font-weight: bold;
  color: #F0F0F0
}
